import { useEffect, useState } from 'react';
import { Loader } from '../../../utils/loader';
import { gql, useApolloClient } from '@apollo/client';
import type {
  LoadScriptErrors,
  LoadScriptErrorsVariables,
  LoadScriptErrors_scriptErrors,
  LoadScriptHistory,
  LoadScriptHistoryVariables,
  LoadScriptHistory_scriptMessages,
  LoadWebhookErrors,
  LoadWebhookErrors_webhookErrors,
} from '@gql';
import { Button } from '@/components/ui/button';
import { delay, last, useTimeAgo } from '@utils';
import { JsonBlock } from '@ui-kit/JsonBlock';
import { ErrorBlock } from '@ui-kit/ErrorBlock';
import { LinkIcon, PlayCircleIcon, SendIcon, TestTube2Icon, Trash2Icon } from 'lucide-react';
import { Pagination, PaginationContent, PaginationItem, PaginationNext, PaginationPrevious } from '@/components/ui/pagination';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { useNavigate } from 'react-router-dom';
import { ScriptDetailsTab } from './ScriptDetailsRoot';
import { Skeleton } from '@/components/ui/skeleton';
import { EmptyListAlert, ErrorAlert } from '@/components/ui/alert';
import { toast } from 'sonner';
import { CodeIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useAuthToken } from '@/apollo';
import { io } from 'socket.io-client';
import { EXPLORER_URLS, networkList } from '@/constants/networks';

const MAX_CNT = 10;

interface WebSocketMessage {
  block: string;
  blockHash: string;
  data: any;
  id: string;
  network: string;
  networkId: number;
  at: string;
}

export function HistoryList({ id, isRealTimeEnabled, onRealTimeStateChange }: { id: ScriptId; isRealTimeEnabled: boolean; onRealTimeStateChange: (value: boolean) => void }) {
  const [messageHistory, setMessageHistory] = useState<LoadScriptHistory['scriptMessages']>([]);
  const [cursor, setCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<(string | null)[]>([]);
  const token = useAuthToken();

  useEffect(() => {
    if (isRealTimeEnabled && cursor) {
      setCursor(null);
      setCursorStack([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRealTimeEnabled]);

  Loader.useWrap({ id, token, isRealTimeEnabled } as const)
    .debounce(1000, true)
    .map(data => data.isRealTimeEnabled ? data : Loader.skipped)
    .onOk(({ id, isRealTimeEnabled, token }) => {
      if (!isRealTimeEnabled) {
        return;
      }

      const messageSocket = io(import.meta.env.VITE_WS_SERVER!, {
        query: {
          stream: id,
          token,
          includeMetadata: true,
        },
        transports: ['websocket'],
      });

      messageSocket.on('message', (message: WebSocketMessage) => {
        setMessageHistory(prev => {
          if (prev.some(_message => _message.id === message.id)) {
            return prev;
          }
          return [
            {
              __typename: 'GScriptMessage',
              at: new Date().toISOString(),
              blockHash: message.blockHash,
              blockNumber: message.block,
              data: message.data,
              id: message.id,
              network: message.network,
              networkId: message.networkId,
              hookStatus: [],
            },
            ...prev.slice(0, 10)
          ];
        });
      })

      messageSocket.connect();

      return () => {
        messageSocket.disconnect();
      };
    })

  const historyLoader = Loader.query<LoadScriptHistory>(
    gql`
      query LoadScriptHistory($id: ScriptId!, $cursor: String, $take: Int!) {
        scriptMessages(script: $id, take: $take, cursor: $cursor, newestFirst: true) {
          id
          blockNumber
          blockHash
          networkId
          network
          data
          at
          hookStatus {hook processed error}
        }
      }
    `,
    {
      variables: { id, cursor, take: MAX_CNT } satisfies LoadScriptHistoryVariables,
    },
  ).map(x => x.scriptMessages);

  historyLoader.onOk(messages => {
    setMessageHistory(messages);
    if (cursor === null && !isRealTimeEnabled) {
      onRealTimeStateChange(true);
    }
  });

  const handlePreviousClick = () => {
    setCursorStack((prevStack) => {
      if (prevStack.length > 0) {
        const newStack = [...prevStack];
        const previousCursor = newStack.pop();
        setCursor(previousCursor || null);
        return newStack;
      }
      return prevStack;
    });
  }

  const handleNextClick = () => {
    setCursorStack((prevStack) => [...prevStack, cursor])
    setCursor(last(messageHistory)?.id!);
    onRealTimeStateChange(false);
  }

  return (
    <div>
      {historyLoader.isLoadingOrSkipped && (
        <div className="flex flex-col gap-2">
          {Array(5).fill(null).map((_, i) => (
            <Skeleton key={i} className="h-40" />
          ))}
        </div>
      )}
      {historyLoader.isError && (
        <ErrorAlert name="Error" message="Error loading history" />
      )}
      {historyLoader.isOk && (
        <div className="flex flex-col gap-2">
          {messageHistory.length === 0 && <EmptyListAlert message='No stored history' />}
          {messageHistory.map(message => (
            <HistoryLine key={message.id} scriptId={id} line={message} />
          ))}
        </div>
      )}

      <Pagination className="py-5">
        <PaginationContent>
          <PaginationItem data-state={cursorStack.length === 0 && 'disabled'} className="cursor-pointer" onClick={handlePreviousClick}>
            <PaginationPrevious />
          </PaginationItem>
          <PaginationItem data-state={messageHistory.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={handleNextClick}>
            <PaginationNext />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
}

function HistoryLine({ line, scriptId }: { scriptId: ScriptId; line: LoadScriptHistory_scriptMessages }) {
  const apollo = useApolloClient();
  const navigateTo = useNavigate();

  const replay = async () => {
    toast.promise(apollo.mutate({
      mutation: gql`mutation ReplayHistoryLine($script: ScriptId!, $msg: String!) {
        replayMessage(scriptId: $script, messageId: $msg)
      }`,
      variables: { script: scriptId, msg: line.id },
    }), {
      loading: 'Replaying...',
      success: 'Replayed successfully',
      error: 'Failed to replay message',
    });
  }

  const handleRunTest = () => {
    navigateTo(`/scripts/${scriptId}?tab=${ScriptDetailsTab.Code}`, { state: { data: line } })
  }

  return <JsonBlock
    json={line.data}
    network={line.network}
    title={
      <div className="flex gap-2 items-center">
        #{`${BigInt(line.blockNumber)}`}&nbsp;
        <HookStatus msg={line} status={line.hookStatus} />
        <a target="_blank" href={`${EXPLORER_URLS[line.network as typeof networkList[number]]}block/${line.blockHash}`}>
          <LinkIcon className="w-4 h-4" />
        </a>
      </div>
    }
    time={line.at}
    actions={
      <>
        <Tooltip>
          <TooltipTrigger>
            <Button size="icon" variant="secondary" onClick={handleRunTest}>
              <TestTube2Icon className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="bg-tooltip">
            Run test
          </TooltipContent>
        </Tooltip>
        <AlertDialog>
          <AlertDialogTrigger>
            <Tooltip>
              <TooltipTrigger>
                <Button size="icon" variant="secondary">
                  <SendIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="bg-tooltip">
                Re-send message
              </TooltipContent>
            </Tooltip>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Re-send confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                Re-send this message to your webhooks/websockets?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={replay}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    } />;
}

export function ExecErrorsList({ id, isRealTimeEnabled, onRealTimeStateChange }: { id: ScriptId; isRealTimeEnabled: boolean; onRealTimeStateChange: (value: boolean) => void }) {
  const [scriptErrors, setScriptErrors] = useState<LoadScriptErrors['scriptErrors']>([]);
  const [cursor, setCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<(string | null)[]>([]);
  const [cnt, setCnt] = useState(0);
  const token = useAuthToken();

  useEffect(() => {
    if (isRealTimeEnabled && cursor) {
      setCursor(null);
      setCursorStack([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRealTimeEnabled]);

  Loader.useWrap({ id, token, isRealTimeEnabled } as const)
    .debounce(1000, true)
    .map(data => data.isRealTimeEnabled ? data : Loader.skipped)
    .onOk(({ id, isRealTimeEnabled, token }) => {
      if (!isRealTimeEnabled) {
        return;
      }

      const messageSocket = io(import.meta.env.VITE_WS_SERVER!, {
        query: {
          errorsStream: `${id}.errors`,
          token,
          includeMetadata: true,
        },
        transports: ['websocket'],
      });

      messageSocket.on('message', (message: WebSocketMessage) => {
        setScriptErrors(prev => {
          if (prev.some(_message => _message.id === message.id)) {
            return prev;
          }
          return [
            {
              __typename: 'GScriptError',
              at: new Date().toISOString(),
              blockHash: message.blockHash,
              blockNumber: message.block,
              errors: message.data,
              id: message.id,
              network: message.network,
              networkId: message.networkId,
            },
            ...prev.slice(0, 10)
          ];
        });
      })

      messageSocket.connect();

      return () => {
        messageSocket.disconnect();
      };
    })

  const historyLoader = Loader.query<LoadScriptErrors>(
    gql`
      query LoadScriptErrors($id: ScriptId!, $cursor: String, $take: Int!) {
        scriptErrors(script: $id, take: $take, cursor: $cursor, newestFirst: true) {
          id
          blockNumber
          blockHash
          networkId
          network
          errors
          at
        }
      }
    `,
    {
      variables: { id, take: MAX_CNT } satisfies LoadScriptErrorsVariables,
      refetchWhenChanges: [cnt],
    },
  ).map(x => x.scriptErrors)
    .noFlickering();

  historyLoader.onOk(messages => {
    setScriptErrors(messages);
    if (cursor === null && !isRealTimeEnabled) {
      onRealTimeStateChange(true);
    }
  });

  const handlePreviousClick = () => {
    setCursorStack((prevStack) => {
      if (prevStack.length > 0) {
        const newStack = [...prevStack];
        const previousCursor = newStack.pop();
        setCursor(previousCursor || null);
        return newStack;
      }
      return prevStack;
    });
  }

  const handleNextClick = () => {
    setCursorStack((prevStack) => [...prevStack, cursor])
    setCursor(last(scriptErrors)?.id!);
    onRealTimeStateChange(false);
  }

  return (
    <div>
      {historyLoader.isLoadingOrSkipped && (
        <div className="flex flex-col gap-2">
          {Array(5).fill(null).map((_, i) => (
            <Skeleton key={i} className="h-40" />
          ))}
        </div>
      )}
      {historyLoader.isError && (
        <ErrorAlert name="Error" message="Error loading execution errors history" />
      )}
      {historyLoader.isOk && (
        <div className="flex flex-col gap-2">
          {scriptErrors.length === 0 && <EmptyListAlert message='No stored history' />}
          {scriptErrors.map(message => (
            <ScriptErrorLine key={message.id} onReload={() => setCnt(x => x + 1)} scriptId={id} line={message} />
          ))}
        </div>
      )}
      <Pagination className="py-5">
        <PaginationContent>
          <PaginationItem data-state={cursorStack.length === 0 && 'disabled'} className="cursor-pointer" onClick={handlePreviousClick}>
            <PaginationPrevious />
          </PaginationItem>
          <PaginationItem data-state={scriptErrors.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={handleNextClick}>
            <PaginationNext />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
}


export function WebhookErrorsList({ id }: { id: ScriptId }) {
  const [cursor, setCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<(string | null)[]>([]);
  const [cnt, setCnt] = useState(0);

  const historyLoader = Loader.query<LoadWebhookErrors>(
    gql`
      query LoadWebhookErrors($id: ScriptId!, $cursor: String, $take: Int!) {
        webhookErrors(script: $id, take: $take, cursor: $cursor, newestFirst: true) {
          id
          blockNumber
          blockHash
          networkId
          network
          error
          data
          at
          fixed
          hook
        }
      }
    `,
    {
      variables: { id, cursor, take: MAX_CNT } satisfies LoadScriptErrorsVariables,
      refetchWhenChanges: [cnt],
    },
  ).map(x => x.webhookErrors)
    .noFlickering();

  const handlePreviousClick = () => {
    setCursorStack((prevStack) => {
      if (prevStack.length > 0) {
        const newStack = [...prevStack];
        const previousCursor = newStack.pop();
        setCursor(previousCursor || null);
        return newStack;
      }
      return prevStack;
    });
  }

  const handleNextClick = (_history: readonly LoadWebhookErrors_webhookErrors[]) => {
    setCursorStack((prevStack) => [...prevStack, cursor])
    setCursor(last(_history)?.id!);
  }

  return (
    <div>
      {historyLoader.match
        .loadingOrSkipped(() => (
          <div className="flex flex-col gap-2">
            {Array(5).fill(null).map((_, i) => (
              <Skeleton key={i} className="h-20" />
            ))}
          </div>
        ))
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(hist => (
          <div className="flex flex-col gap-2">
            {hist.map(x => (
              <WebhookErrorLine key={x.id} onReload={() => setCnt(x => x + 1)} scriptId={id} line={x} />
            ))}

            {hist.length === 0 && <EmptyListAlert message='No errors' />}

            <Pagination className="py-5">
              <PaginationContent>
                <PaginationItem data-state={cursorStack.length === 0 && 'disabled'} className="cursor-pointer" onClick={handlePreviousClick}>
                  <PaginationPrevious />
                </PaginationItem>
                <PaginationItem data-state={hist.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={() => handleNextClick(hist)}>
                  <PaginationNext />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        ))}
    </div>
  );
}

function ScriptErrorLine({ line, scriptId, onReload }: { scriptId: ScriptId; onReload: () => void; line: LoadScriptErrors_scriptErrors }) {
  const apollo = useApolloClient();
  const navigateTo = useNavigate();

  const wrapReload = async (pause: boolean, promise: Promise<any>) => {
    await promise;
    if (pause) {
      await delay(1000);
    }
    onReload();
  }

  const replay = async () => {
    toast.promise(wrapReload(true, apollo.mutate({
      mutation: gql`mutation ReplayErrorLine($script: ScriptId!, $id: String!) {
        replayError(scriptId: $script, errorId: $id)
      }`,
      variables: { script: scriptId, id: line.id },
    })), {
      loading: 'Retrying...',
      success: 'Retry successfully sent',
      error: 'Failed to retry block',
    });
  }

  const deleteErr = async () => {
    toast.promise(wrapReload(false, apollo.mutate({
      mutation: gql`mutation DeleteErrorLine($script: ScriptId!, $id: String!) {
        deleteError(scriptId: $script, errorId: $id)
      }`,
      variables: { script: scriptId, id: line.id },
    })), {
      loading: 'Deleting...',
      success: 'Deleted successfully',
      error: 'Failed to delete error',
    });
  }

  const handleRunTest = () => {
    navigateTo(`/scripts/${scriptId}?tab=${ScriptDetailsTab.Code}`, { state: { data: line } })
  }

  const errTxts = line.errors.map(e => {
    if (typeof e === 'string') {
      return e;
    } else if ('error' in e && typeof e.error === 'string' && Object.keys(e).length === 1) {
      return e.error;
    } else {
      return JSON.stringify(e, null, '  ');
    }
  })
  return <ErrorBlock
    error={errTxts}
    network={line.network}
    title={'#' + BigInt(line.blockNumber)}
    time={line.at}
    actions={
      <>
        <Tooltip>
          <TooltipTrigger>
            <Button size="icon" variant="secondary" onClick={handleRunTest}>
              <TestTube2Icon className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="bg-tooltip">
            Run test
          </TooltipContent>
        </Tooltip>
        <AlertDialog>
          <AlertDialogTrigger>
            <Tooltip>
              <TooltipTrigger>
                <Button size="icon" variant="secondary">
                  <PlayCircleIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="bg-tooltip">
                Replay
              </TooltipContent>
            </Tooltip>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Re-send confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                Re-send this message to your webhooks/websockets?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={replay}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog>
          <AlertDialogTrigger>
            <Button size="icon" variant="secondary">
              <Trash2Icon className="w-4 h-4" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete this error?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={deleteErr}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    }
  />;
}


function copyCurlCommand(line: LoadWebhookErrors_webhookErrors | LoadScriptHistory_scriptMessages, url: string) {
  navigator.clipboard.writeText(
    `curl -X POST ${url} -H "Content-Type: application/json" -H 'id: ${line.id}' -H 'network: ${line.network}' -H 'network-id: ${line.networkId}' -H 'block-number: ${line.blockNumber}' -H 'block-hash: ${line.blockNumber}' -d '${JSON.stringify(line.data).replace(/'/g, "\\'")}'`
  );
  toast.info('curl command copied to clipboard');
}



function WebhookErrorLine({ line, scriptId, onReload }: { scriptId: ScriptId; onReload: () => void; line: LoadWebhookErrors_webhookErrors }) {

  return <JsonBlock
    json={line.data}
    network={line.network}
    actions={
      <Tooltip>
        <TooltipTrigger>
          <Button size="icon" variant="secondary" onClick={() => copyCurlCommand(line, line.hook)}>
            <CodeIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-tooltip">
          Copy curl command
        </TooltipContent>
      </Tooltip>
    }
    title={<>
      #{line.blockNumber}
      &nbsp;
      {line.fixed ? <span className="text-xs text-green-500"> ✅ {line.error} (retry success)</span> : <span className='text-xs text-red-500'>❌ {line.error}</span>}
    </>}
    time={line.at}
  />;
}


export function HookStatus({ msg, status }: { msg: LoadScriptHistory_scriptMessages; status: LoadScriptHistory_scriptMessages['hookStatus'] }) {
  const hasError = status.some(x => x.error);
  const hasNotProcessed = status.some(x => !x.processed);

  let icon: string;
  if (hasError) {
    icon = '❌';
  } else if (hasNotProcessed) {
    icon = '⏳';
  } else if (status.length) {
    icon = '✅';
  } else {
    return;
  }

  return (
    <Tooltip>
      <TooltipTrigger>{icon}</TooltipTrigger>
      <TooltipContent className="bg-tooltip">
        {status.map((x) => <HookLine key={x.hook} msg={msg} status={x} />)}
      </TooltipContent>
    </Tooltip>
  )
}

function HookLine({ msg, status }: { msg: LoadScriptHistory_scriptMessages; status: LoadScriptHistory_scriptMessages['hookStatus'][0] }) {
  const ago = useTimeAgo(status.processed);
  return (
    <div className='whitespace-nowrap flex gap-2 items-center'>
      <span className='text-xs'>{status.hook} : </span>
      {status.error ? `❌` : status.processed ? `✅ ${ago}` : `⏳`}

      <Button size="icon" variant="secondary" onClick={() => copyCurlCommand(msg, status.hook)}>
        <CodeIcon />
      </Button>
    </div>
  )
}
