import { Children, Cls, Elt, useTimeAgo } from '@utils';
import { PayloadTitle } from '@/components/ui/payload-title';
import { Button } from '@/components/ui/button';
import { ClipboardIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ReactNode } from 'react';
import { toast } from 'sonner';
import { ArbitrumIcon, AvalancheIcon, BaseIcon, BnbChainIcon, EthereumIcon, OptimismIcon, PolygonIcon } from './Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

const NetworkIcon = {
  arbitrum: ArbitrumIcon,
  base: BaseIcon,
  binance: BnbChainIcon,
  ethereum: EthereumIcon,
  optimism: OptimismIcon,
  polygon: PolygonIcon,
  avalanche: AvalancheIcon,
}

export interface NetworkBlockProps {
  time?: string | number | Date;
  channel?: string;
  network?: string;
  title?: Elt;
  copy?: string;
  actions?: ReactNode;
}

export function NetworkBlock({
  children,
  network,
  title,
  time,
  channel,
  copy,
  actions,
}: Children & Cls & NetworkBlockProps) {
  // trigger refresh every second to update the "ago" display
  const ago = useTimeAgo(time);
  const Network = network ? NetworkIcon[network as keyof typeof NetworkIcon] : () => null;

  const onCopy = () => {
    if (copy) {
      navigator.clipboard.writeText(copy);
      toast.info('Copied to clipboard');
    }
  };

  return (
    <div className="rounded-lg bg-secondary">
      <PayloadTitle
        className="w-full bg-secondary rounded-t-lg border-b"
        title={
          <div className="flex gap-1 items-center">
            <Network className="w-8 h-8 rounded-full" />
            {title}
          </div>
        }
        actions={
          <div className="flex gap-2 flex-1 justify-end">
            {actions}
            {copy && (
              <Tooltip>
                <TooltipTrigger>
                  <Button size="icon" variant="secondary" onClick={onCopy}>
                    <ClipboardIcon />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-tooltip">
                  Copy
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        }
      />
      <ScrollArea className="h-full">
        <div className="max-h-[250px]">{children}</div>
      </ScrollArea>
      <div className="flex bg-muted rounded-b-lg items-center">
        {channel ? (
          <div className="flex p-2 gap-2 items-center flex-1 text-xs font-medium h-8">
            <QuestionMarkCircledIcon className="w-3 h-3" />
            <span className="text-secondary-foreground">In channel:</span>
            <span className="text-muted-foreground">{channel}</span>
          </div>
        ) : (
          <div className="flex-1 h-8" />
        )}
        {time && <div className="px-2 text-xs font-medium">{ago}</div>}
      </div>
    </div>
  );
}
