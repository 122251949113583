import { EmptyListAlert, ErrorAlert } from '@/components/ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Progress } from '@/components/ui/progress';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableLoader } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { AddAddressListElt, GetAddressList, GetAddressListVariables, SearchAddress } from '@gql';
import { UpdateIcon } from '@radix-ui/react-icons';
import { Loader, isHexString, isValidAddress } from '@utils';
import clsx from 'clsx';
import { ImportIcon, PlusSquareIcon, Trash2Icon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

export function AddressListDetails() {
  const { list } = useParams();
  const [newAddress, setNewAddress] = useState('');
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search');
  const [isSearchAddressValid, setIsSearchAddressValid] = useState<boolean>();
  const { CSVReader } = useCSVReader();
  const [addressesToImport, setAddressesToImport] = useState<HexString[]>([]);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [importedAddressesCount, setImportedAddressesCount] = useState(0);

  const [cnt, setcnt] = useState(0);
  const refresh = () => setcnt(x => x + 1);
  const apollo = useApolloClient();

  useEffect(() => {
    if (searchValue) {
      setIsSearchAddressValid(isValidAddress(searchValue));
    } else {
      setIsSearchAddressValid(false);
    }
  }, [searchValue]);

  useEffect(() => {

  }, [addressesToImport]);

  const listResult = Loader.query<GetAddressList>(
    gql`
      query GetAddressList($list: String!) {
        addressList(name: $list) {
          name
          list
          count
        }
      }
    `,
    {
      variables: {
        list: list ?? '',
      } satisfies GetAddressListVariables,
      refetchWhenChanges: [cnt],
    },
  ).map(x => x.addressList);

  const searchResult = Loader
    .useWrap(searchValue)
    .map((searchValue) => searchValue && isValidAddress(searchValue.trim()) ? searchValue.trim() : Loader.skipped)
    .debounce(500, true)
    .query<SearchAddress>(
      gql`
      query SearchAddress($list: String!, $search: HexString!) {
        addressList(name: $list) {
          has(item: $search)
        }
      }
    `,
      search => ({ list: list, search }),
    ).map(x => x.addressList?.has);

  const addAddress = async () => {
    if (!isHexString(newAddress)) {
      toast.error('Expecting hex sting, prefixed with 0x');
      return;
    }
    if (newAddress.length !== 42 && newAddress.length !== 66) {
      toast.error('Expecting either an address (42 chars), or a hash (66 chars)');
      return;
    }

    try {
      await addNewAddress(list!, newAddress, apollo);
      refresh();
      toast.success('Address list element added !');
      setNewAddress('');
      setIsAddAddressModalOpen(false);
    } catch (e) {
      console.error('failed to add address list element', e);
      toast.error('Failed to add address list element');
    }
  };

  const handleAddressDelete = async (value: string, list: string) => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation DeleteAddressListElt($list: String!, $elt: HexString!) {
            addressListRemove(name: $list, items: [$elt])
          }
        `,
        variables: {
          list,
          elt: value,
        },
      });
      toast.success('Address list element deleted !');
      refresh();
    } catch (e) {
      console.error('failed to delete address list element', e);
      toast.error('Failed to delete address list element');
    }
  };

  const handleSearchValueChange = (value: string) => {
    setSearchParams({ search: value }, { replace: true });
  }

  const handleAddNewSearchedAddress = async (_newAddress: HexString) => {
    await addNewAddress(list!, _newAddress, apollo);
    refresh();
    toast.success('Address list element added !');
    setSearchParams({ search: '' }, { replace: true });
  }

  const handleImport = ({ data }: any) => {
    const currentList = listResult.match.notOk(() => [] as HexString[]).ok(_result => _result?.list);
    const validAddresses = (data as string[][]).map(line => line[0]).filter(item => isValidAddress(item) && !currentList?.includes(item));
    setAddressesToImport(validAddresses as HexString[]);
    setIsImportDialogOpen(true);
  }

  const handleImportDialogOpenChange = (isOpen: boolean) => {
    setIsImportDialogOpen(isOpen);
    if (!isOpen) {
      setAddressesToImport([]);
    }
  }

  const handleImportConfirm = async () => {
    try {
      let addedAddressesCount = 0;
      for (const address of addressesToImport) {
        const wasSuccessfullyAdded = await addNewAddress(list!, address, apollo);
        if (wasSuccessfullyAdded.data?.addressListAdd) {
          setImportedAddressesCount(prev => prev + 1);
          addedAddressesCount += 1;
        }
      }
      refresh();
      setImportedAddressesCount(0);
      setAddressesToImport([]);
      setIsImportDialogOpen(false);
      toast.success(`${addedAddressesCount} addresses imported`);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
          <div className="flex-1 py-1">
            <h4>Addresses</h4>
          </div>
          <div className="relative">
            <Input
              className={clsx("h-10 bg-secondary w-min", !isSearchAddressValid && searchValue && '!ring-destructive')}
              placeholder='Search...'
              value={searchValue as string}
              onChange={e => handleSearchValueChange(e.target.value)}
            />
            {!isSearchAddressValid && searchValue && <span className="text-sm text-destructive absolute -bottom-5">Invalid address</span>}
          </div>
          <Button size="lg" variant="secondary" onClick={refresh} className="flex items-center gap-2">
            <UpdateIcon className="w-4 h-4" />
            Refresh
          </Button>
          <CSVReader
            onUploadAccepted={handleImport}
            noDrag
          >
            {({ getRootProps }: any) => (
              <div {...getRootProps()}>
                <Button size="lg" variant="secondary" className="flex items-center gap-2">
                  <ImportIcon className="w-4 h-4" />
                  Import CSV
                </Button>
              </div>
            )}
          </CSVReader>
          <Dialog open={isAddAddressModalOpen} onOpenChange={setIsAddAddressModalOpen}>
            <DialogTrigger asChild>
              <Button size="lg" className="flex items-center gap-2">
                <PlusSquareIcon className="w-4 h-4" />
                <span>Add address</span>
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>New address</DialogTitle>
                <DialogDescription>Add new address</DialogDescription>
              </DialogHeader>
              <div>
                <Input
                  className="h-10 bg-secondary border-none rounded-xl"
                  value={newAddress}
                  onChange={e => setNewAddress(e.target.value)}
                />
              </div>
              <DialogFooter>
                <Button disabled={!newAddress} size="lg" type="submit" onClick={() => addAddress()}>
                  Add
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        {!isSearchAddressValid
          ? listResult.match
            .loadingOrSkipped(() => <TableLoader />)
            .error((e) => <ErrorAlert name={e.name} message={e.message} />)
            .ok(addressList => (
              addressList?.list.length === 0 ? (
                <EmptyListAlert message='No address yet' />
              ) :
                <List addressList={addressList?.list as HexString[]} onAddressDelete={(value) => handleAddressDelete(value, list!)} />
            ))
          : (
            searchResult.match
              .loadingOrSkipped(() => <TableLoader />)
              .error((e) => <ErrorAlert name={e.name} message={e.message} />)
              .ok(isInList => (
                isInList
                  ? <List addressList={[searchValue as HexString]} onAddressDelete={(value) => handleAddressDelete(value, list!)} />
                  : (
                    <EmptyListAlert
                      message='This address is not in present'
                      action={<Button onClick={() => handleAddNewSearchedAddress(searchValue as HexString)}>{`Add '${searchValue}' to '${list}'`}</Button>}
                    />
                  )
              ))
          )}
      </div>
      <AlertDialog open={isImportDialogOpen} onOpenChange={handleImportDialogOpenChange}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Import addresses</AlertDialogTitle>
          </AlertDialogHeader>
          <div className="flex flex-col gap-1 text-sm">
            {addressesToImport.map(address => <span>{address}</span>)}
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={handleImportConfirm}>Import</Button>
          </AlertDialogFooter>
          {addressesToImport.length > 0 && 
            <Progress value={(importedAddressesCount / addressesToImport.length) * 100} />
          }
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

async function addNewAddress(list: string, newAddress: HexString, apollo: ApolloClient<object>) {
  return apollo.mutate<AddAddressListElt>({
    mutation: gql`
      mutation AddAddressListElt($list: String!, $elt: HexString!) {
        addressListAdd(name: $list, items: [$elt])
      }
    `,
    variables: {
      list,
      elt: newAddress,
    },
  });
}

type ListProps = {
  addressList: HexString[] | undefined;
  onAddressDelete: (value: string) => void;
}

function List({ addressList, onAddressDelete }: ListProps) {
  return (
    <ScrollArea>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Address</TableHead>
            <TableHead>Added date</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {addressList?.map(address => (
            <TableRow data-type="secondary" key={address}>
              <TableCell className="font-normal text-base leading-7">{address}</TableCell>
              <TableCell />
              <TableCell className="text-right pr-5">
                <AlertDialog>
                  <AlertDialogTrigger>
                    <Button variant="secondary" size="icon">
                      <Trash2Icon className="w-4 h-4" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>{`Delete "${address}" confirmation`}</AlertDialogTitle>
                      <AlertDialogDescription>
                        Are you sure you want to delete this addrress?
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => onAddressDelete(address)}>Confirm</AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}
