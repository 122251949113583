import { useTheme } from "@/components/theme-provider";
import { Editor } from "@monaco-editor/react";

type PayloadProps = {
  value?: string | undefined;
  initialValue?: JSON | nil;
  onChange?: (value: string | undefined) => void;
}

export function Payload({ value, onChange, initialValue }: PayloadProps) {
  const { theme } = useTheme();

  return (
    <Editor
      language="json"
      onChange={(value) => onChange?.(value)}
      options={{
        autoIndent: 'full',
        minimap: { enabled: false },
        fontSize: 12,
        lineHeight: 14,
        fontWeight: 300,
        lineNumbers: 'on',
        readOnly: !onChange,
        scrollbar: {
          verticalScrollbarSize: 7,
          horizontalScrollbarSize: 7,
        }
      }}
      height="250px"
      defaultValue={JSON.stringify(initialValue, null, 2)}
      value={value}
      theme={theme === 'dark' ? 'vs-dark' : 'light'}
    />
  )
}