import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { StethoscopeIcon } from 'lucide-react';
import { Test } from './Test';
import { Accordion } from '@/components/ui/accordion';
import { gql } from '@apollo/client';
import { UnitTests } from '@gql'
import { Loader } from '@/utils/loader';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@/components/ui/skeleton';
import { EmptyListAlert, ErrorAlert } from '@/components/ui/alert';
import { useRef, useState } from 'react';
import { ScriptState } from '../useScript';
import React from 'react';

type UnitTestsListProps = {
  isDraft: boolean;
  script: ScriptState['draft'];
}

export function UnitTestsList({ isDraft, script }: UnitTestsListProps) {
  const scriptId = useParams().scriptId as ScriptId;
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const testRefs = useRef<Record<string, React.RefObject<any>>>({});

  const tests = Loader.query<UnitTests>(
    gql`
      query UnitTests($scriptId: ScriptId!) {
        unitTests(scriptId: $scriptId) {
          blockHash
          blockNumber
          expectedEmits
          name
          network
        }
      }
    `,
    {
      variables: {
        scriptId,
      },
      refetchWhenChanges: [refreshTrigger],
    }
  ).map((data: UnitTests) => data.unitTests);

  const handleRunAllClick = async () => {
    for (const test of tests.match.notOk(() => []).ok(unitTests => unitTests)) {
      const testRef = testRefs.current[test.name];
      if (testRef && testRef.current) {
        try {
          await testRef.current.handleRunTestClick();
        } catch (e) {
          console.error(`Test ${test.name} failed`, e);
        }
      }
    }
  };

  return (
    <div className="flex flex-col overflow-clip">
      <div className="flex h-full w-full">
        <ScrollArea className="w-full">
          <div className="flex flex-col">
            <Accordion type="multiple" className="px-2.5 flex flex-col">
              {tests.match
                .loadingOrSkipped(() =>
                  <>
                    <Skeleton className="h-4 w-full my-2" />
                    <Skeleton className="h-4 w-full my-2" />
                    <Skeleton className="h-4 w-full my-2" />
                  </>
                )
                .error((e) => <ErrorAlert name={e.name} message={e.message} />)
                .ok((unitTests) =>
                  unitTests.length === 0
                    ? <EmptyListAlert message='No unit tests yet' />
                    : unitTests.map(test => {
                      const ref = React.createRef<any>();
                      testRefs.current[test.name] = ref;
                      return <Test ref={ref} onRefresh={() => setRefreshTrigger(prev => prev + 1)} script={script} testArgs={test} isDraft={isDraft} key={test.name} name={test.name} />
                    }
                    )
                )
              }
            </Accordion>
            <div className="pt-4">
              <Button onClick={handleRunAllClick} variant="secondary" className="w-full flex items-center gap-2" size="lg">
                <StethoscopeIcon className="w-4 h-4" />
                Run all
              </Button>
            </div>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
