import { Accordion } from '@/components/ui/accordion';
import { Simulate } from './Simulate';
import { ScriptState } from './useScript';
import { useState } from 'react';

type SimulationProps = {
  script: ScriptState;
  isDraft: boolean;
};

export function SideContent({ script, isDraft }: SimulationProps) {
  const [currentContent, setCurrentContent] = useState<string>('simulate');

  return (
    <Accordion
      value={currentContent}
      onValueChange={setCurrentContent}
      type="single"
      collapsible={false}
      defaultValue="simulate"
      className="w-full flex flex-col h-full"
    >
      <Simulate isDraft={isDraft} script={script} isOpen={currentContent === 'simulate'} />
    </Accordion>
  );
}
