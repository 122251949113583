import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Edit3Icon } from "lucide-react";
import { Payload } from "./Payload";
import { useState } from "react";

type EditExpectedEmitsProps = {
  value: JSON | nil;
  onConfirm: (newExpectedEmits: any) => void;
}

export function EditExpectedEmits({ value, onConfirm }: EditExpectedEmitsProps) {
  const [newValue, setNewValue] = useState<string>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleConfirmClick = () => {
    if (newValue) {
      onConfirm(JSON.parse(newValue));
    }
    setIsDialogOpen(false);
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger>
        <Button variant="ghost" className="gap-2">
          <Edit3Icon className="w-4 h-4" />Edit expected emits
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit expected emits</DialogTitle>
          <DialogDescription>
            Edit expected emits
          </DialogDescription>
        </DialogHeader>
        <Payload initialValue={value} value={newValue} onChange={setNewValue} />
        <DialogFooter>
          <Button disabled={!newValue} onClick={handleConfirmClick}>
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>

  )
}