import { format } from 'date-fns';

export function isHexString(str: string): str is HexString {
  return !!str && /^0x[0-9a-fA-F]+$/.test(str);
}


const DATE_FORMATS= {

  date: 'YYYY/MM/DD',
  recentDate: 'MM/DD',
  // to display date-time in a weekly or monthly view
  recentDateTime: 'MM/DD HH:mm',
} as const;
export type DateFormat = keyof typeof DATE_FORMATS;

export function formatDate(value: string | Date, dateFormat: DateFormat): string {
  if (!value) {
    return '';
  }
  const newValue = new Date(value);
  return format(newValue, DATE_FORMATS[dateFormat]);
}

export function isValidEmail(email: string): boolean {
  // RFC2822 email regex

  const pattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;


  return pattern.test(email.toLowerCase());
}

export const isValidAddress = (address: string | undefined): address is HexString =>
  !!address?.match(/^0x[a-fA-F0-9]{40}$/);


export const formatPercentage = (value: number): string => {
  const positiveValue = Math.max(0, value);
  if (positiveValue === 0) {
    return '0%';
  }
  if (positiveValue < 0.005) {
    return '< 0.5%';
  }
  return `${Math.round(value * 100)}%`;
};

/** Returns a promise that will resolve after a given period */
export function delay(ms: number): Promise<void> {
  const ret = new Promise<void>(resolve => setTimeout(resolve, ms));
  return ret;
}


export function shortenAddress(zx?: HexString | null, veryShort?: boolean) {
  if (!zx) {
    return '-';
  }

  return `${zx.substr(0, veryShort ? 3 : 10)}…${zx.substr(zx.length - (veryShort ? 3 : 9))}`;
}

export function sortJsonKeys<T>(obj: T): T {
  if (Array.isArray(obj)) {
      // If it's an array, sort each element recursively
      return obj.map(sortJsonKeys) as T;
  } else if (obj !== null && typeof obj === 'object') {
      // If it's an object, sort its keys
      const sortedObj: Record<string, unknown> = {};
      Object.keys(obj)
          .sort()
          .forEach((key) => {
              sortedObj[key] = sortJsonKeys((obj as Record<string, unknown>)[key]);
          });
      return sortedObj as T;
  } else {
      // If it's neither an object nor an array, return the value as is
      return obj;
  }
}
