import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Edit3Icon } from "lucide-react";
import { useState } from "react";

type EditTestNameProps = {
  currentName: string;
  onConfirm: (newName: string) => void;
  isDisabled?: boolean;
}

export function EditTestName({ currentName, onConfirm, isDisabled }: EditTestNameProps) {
  const [name, setName] = useState(currentName);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Edit3Icon data-disabled={isDisabled} role="button" className="w-4 h-4 data-[disabled=true]:text-muted-foreground data-[disabled=true]:cursor-default data-[disabled=true]:pointer-events-none" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit test name</DialogTitle>
          <DialogDescription>
            Provide new name for unit test
          </DialogDescription>
        </DialogHeader>
        <Input
          autoFocus
          value={name}
          onChange={e => setName(e.target.value)} />
        <DialogFooter>
          <Button onClick={() => onConfirm(name)}>Confirm</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}