import { Button } from "./button";
import { PayloadTitle } from "./payload-title";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { useMemo, useState } from "react";
import { ClipboardIcon, GearIcon, QuestionMarkIcon } from "@radix-ui/react-icons";
import { Checkbox } from "./checkbox";
import { WarningIcon } from "@ui-kit/Icons";
import clsx from "clsx";
import { Editor } from '@monaco-editor/react';
import { useTheme } from "../theme-provider";
import { Link } from "react-router-dom";
import { KeyRoundIcon, PlusSquare } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "./dropdown-menu";
import { gql, useApolloClient } from "@apollo/client";
import { Loader } from "@/utils/loader";
import { GetApiKeys } from "@gql";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./dialog";
import { Input } from "./input";
import { toast } from "sonner";
import { useAuthToken } from "@/apollo";


type CodeSampleProps = {
  title: string;
  docUrl: string;
  warning?: string;
  sampleWithSdk: string;
  sampleWithoutSdk?: string;
  className?: string;
  scriptId?: ScriptId;
}

export function CodeSample({ title, docUrl, warning, scriptId, sampleWithSdk, sampleWithoutSdk, className }: CodeSampleProps) {
  const { theme } = useTheme();
  const apollo = useApolloClient();
  const [shouldUseSdk, setShouldUseSdk] = useState(true);
  const token = useAuthToken();
  const [currentApiKey, setCurrentApiKey] = useState<string | null>(token);
  const [isAddApiTokenModalOpen, setIsAddApiTokenModalOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [newApiToken, setNewApiToken] = useState('');

  const refresh = () => setRefreshTrigger(prev => prev + 1);

  const currentSample = useMemo(() => {
    const _currentSample = shouldUseSdk ? sampleWithSdk : sampleWithoutSdk;
    if (currentApiKey && _currentSample) {
      return _currentSample
        .replace('<your bloomr api key>', currentApiKey)
        .replace('<script id>', scriptId as string);
    }
    return _currentSample?.replace('<script id>', scriptId as string);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldUseSdk, currentApiKey, scriptId],
  );

  const apiKeys = Loader.query<GetApiKeys>(
    gql`
      query GetApiKeys {
        getApiTokens {
          name
          token
        }
      }
    `,
    {
      refetchWhenChanges: [refreshTrigger],
    },
  ).map(x => x.getApiTokens);

  const handleCopy = () => {
    const sample = shouldUseSdk ? sampleWithSdk : sampleWithoutSdk;
    if (currentApiKey && sample) {
      navigator.clipboard.writeText(sample
        .replace('<your bloomr api key>', currentApiKey)
        .replace('<script id>', scriptId as string)
      );
      return;
    }
    navigator.clipboard.writeText(sample!);
  }

  const addApiToken = async () => {
    try {
      const addedToken = await apollo.mutate({
        mutation: gql`
          mutation AddNewApiKey($name: String!) {
            createApiToken(name: $name)
          }
        `,
        variables: {
          name: newApiToken,
        },
      });
      setCurrentApiKey(addedToken.data.createApiToken);
      toast.success('API token added!');
      setNewApiToken('');
      setIsAddApiTokenModalOpen(false);
      refresh();
    } catch (e) {
      console.error('failed to add api token', e);
      toast.error('failed to add api token');
    }
  };

  return (
    <div className={clsx("rounded-lg bg-secondary", className)}>
      <PayloadTitle
        title={title}
        actions={(
          <div className="flex gap-2 flex-1 justify-end">
            <Tooltip>
              <TooltipTrigger>
                <Button size="icon" variant="secondary" onClick={handleCopy}>
                  <ClipboardIcon />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="bg-tooltip">
                Copy
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger>
                <a href={docUrl} target="_blank">
                  <Button size="icon" variant="secondary">
                    <QuestionMarkIcon />
                  </Button>
                </a>
              </TooltipTrigger>
              <TooltipContent className="bg-tooltip">
                Doc
              </TooltipContent>
            </Tooltip>
          </div>
        )}
      />
      <div className="h-40 relative">
        <Editor
          value={currentSample}
          options={{
            autoIndent: 'full',
            minimap: { enabled: false },
            readOnly: true,
            fontSize: 14,
            lineHeight: 20,
            fontWeight: 400,
            scrollbar: {
              verticalScrollbarSize: 7,
              horizontalScrollbarSize: 7,
            }
          }}
          theme={theme === 'dark' ? 'vs-dark' : 'light'}
          defaultLanguage="typescript"
        />
        <div className="absolute top-4 left-4 opacity-50 cursor-pointer hover:opacity-100">
          <Dialog open={isAddApiTokenModalOpen} onOpenChange={setIsAddApiTokenModalOpen}>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <KeyRoundIcon className="w-4 h-4" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>API key</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {apiKeys.match.notOk(() => null).ok(keys =>
                  keys.map(key => (
                    <DropdownMenuItem key={key.token} onClick={() => setCurrentApiKey(key.token)}>
                      {key.name}
                    </DropdownMenuItem>
                  ))
                )}
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <DialogTrigger className="flex items-center gap-2">
                    <PlusSquare className="w-4 h-4" />
                    Add new API key
                  </DialogTrigger>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>New API token</DialogTitle>
                <DialogDescription>Create</DialogDescription>
              </DialogHeader>
              <div>
                <Input
                  className="h-10 bg-secondary border-none rounded-xl"
                  value={newApiToken}
                  onChange={e => setNewApiToken(e.target.value)}
                />
              </div>
              <DialogFooter>
                <Button disabled={!newApiToken} size="lg" type="submit" onClick={() => addApiToken()}>
                  Add
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="flex bg-muted rounded-b-lg items-center justify-between p-3 text-sm min-h-11">
        {warning
          ? (
            <div className="py-2 flex gap-2 items-center text-warning">
              <WarningIcon className="w-5 h-5" />
              {warning}
              <Tooltip>
                <TooltipTrigger>
                  <Link to={`/scripts/${scriptId}?tab=details`}>
                    <Button size="icon" variant="ghost">
                      <GearIcon />
                    </Button>
                  </Link>
                </TooltipTrigger>
                <TooltipContent className="bg-tooltip">
                  Fix
                </TooltipContent>
              </Tooltip>
            </div>
          )
          : <div />
        }
        {!!sampleWithSdk && !!sampleWithoutSdk
          ? <div className="flex items-center gap-2 cursor-pointer">
            <Checkbox id="use-sdk" checked={shouldUseSdk} onCheckedChange={() => setShouldUseSdk(prev => !prev)} />
            <label
              htmlFor="use-sdk"
              className="cursor-pointer"
            >
              Use Bloomr SDK
            </label>
          </div>
          : null
        }
      </div>
    </div>
  )
}
