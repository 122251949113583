import { gql } from '@apollo/client';
import { Loader } from '@utils';
import { BillingGraph } from './BillingGraph';
import { LoadGlobalBillingGraph } from '@gql';
import { BillingGraphPerScript } from './BillingGraphperScript';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { useState } from 'react';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Switch } from '@/components/ui/switch';

enum Tab {
  Type = 'costs-by-type',
  Script = 'costs-by-script',
}

const tabsTriggerClassName =
  'data-[state=active]:bg-transparent border-b-2 border-transparent data-[state=active]:border-b-2 data-[state=active]:border-foreground rounded-none p-2.5 text-base leading-7 font-normal';

export function BillingRoot() {
  const [usd, setusd] = useState(true);

  const byCategory = Loader.query<LoadGlobalBillingGraph>(
    gql`
      query LoadGlobalBillingGraph {
        billingGraph
        billingItems {
          id
          name
          category
        }
      }
    `,
  );

  return (
    <Tabs defaultValue={Tab.Type} className="h-full flex flex-col">
      <div className="flex flex-col">
        <div className="p-4">
          <div className="py-1 gap-[18px] flex items-center">
            <h4 className="flex-1">Billing</h4>
            <div className="flex items-center gap-2">
              <Switch checked={usd} onCheckedChange={setusd} id="prefer-usd" />
              <Label htmlFor="prefer-usd">Estimated $ value</Label>
            </div>
          </div>
        </div>
        <TabsList className="bg-transparent w-full rounded-none p-0 h-auto justify-start border-b">
          <TabsTrigger className={tabsTriggerClassName} value={Tab.Type}>
            Costs by type
          </TabsTrigger>
          <TabsTrigger className={tabsTriggerClassName} value={Tab.Script}>
            Costs by script
          </TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value={Tab.Type} className="flex flex-col gap-6 pt-6 overflow-hidden">
        <ScrollArea>
          <BillingGraph data={byCategory} usd={usd} />
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </TabsContent>
      <TabsContent value={Tab.Script} className="flex flex-col gap-6 pt-6 overflow-hidden">
        <ScrollArea>
          <BillingGraphPerScript usd={usd} />
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </TabsContent>
    </Tabs>
  );

}
