import { AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { gql, useApolloClient } from "@apollo/client";
import { RunUnitTest, UnitTests_unitTests } from "@gql";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import { AlertOctagonIcon, CheckIcon, ChevronRightIcon, LoaderCircleIcon, PlayCircleIcon, TestTubeDiagonalIcon, Trash2Icon } from "lucide-react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { EditTestName } from "./EditTestName";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Payload } from "./Payload";
import { DiffEditor } from "@monaco-editor/react";
import { useTheme } from "@/components/theme-provider";
import { sortJsonKeys } from "@utils";
import { EditExpectedEmits } from "./EditExpectedEmits";
import { ScriptState } from "../useScript";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

type TestProps = {
  name: string;
  isDraft: boolean;
  script: ScriptState['draft'];
  testArgs: UnitTests_unitTests;
  onRefresh: VoidFunction;
}

export const Test = forwardRef(({ name, isDraft, testArgs, onRefresh, script }: TestProps, ref) => {
  const apollo = useApolloClient();
  const scriptId = useParams().scriptId as ScriptId;
  const [status, setStatus] = useState<'success' | 'error' | 'running' | 'default'>('default');
  const [expected, setExpected] = useState<any>();
  const [emitted, setEmitted] = useState<any>();
  const { theme } = useTheme();

  const statusIcon = useMemo(() => {
    switch (status) {
      case 'error':
        return <AlertOctagonIcon className="w-4 h-4 text-destructive" />;
      case 'success':
        return <CheckCircledIcon className="w-4 h-4 text-success" />;
      case 'running':
        return <LoaderCircleIcon className="w-4 h-4 animate-spin" />;
      default:
        return <TestTubeDiagonalIcon className="w-4 h-4 text-muted-foreground" />;
    }
  }, [status]);

  const handleRunTestClick = async () => {
    setStatus('running');
    toast.promise(
      apollo.mutate({
        mutation: gql`
          mutation RunUnitTest($test: UnitTestRef!) {
            runUnitTest(test: $test) {
              passed
              emitted
              expected
            }
          }
        `,
        variables: {
          test: {
            blockHash: testArgs.blockHash,
            network: testArgs.network,
            scriptId,
            onDraft: isDraft,
            withTsCode: isDraft ? script.code : undefined,
            withFilter: isDraft ? script.filter : undefined,
          },
        },
      }),
      {
        loading: 'Running unit test...',
        success: (data) => {
          const _data = data.data as RunUnitTest;
          setStatus(_data.runUnitTest.passed ? 'success' : 'error');
          setExpected(sortJsonKeys(_data.runUnitTest.expected));
          setEmitted(sortJsonKeys(_data.runUnitTest.emitted));
          return 'Test run successfully';
        },
        error: e => {
          console.error('Failed to run unit test', e);
          return `Failed to run unit test`;
        },
      }
    )
  }

  useImperativeHandle(ref, () => ({
    handleRunTestClick,
  }));

  const handleDeleteConfirm = () => {
    toast.promise(
      apollo.query({
        query: gql`
          query DeleteUnitTest($scriptId: ScriptId!, $blockHash: HexString!, $network: String!) {
            deleteUnitTest(scriptId: $scriptId, blockHash: $blockHash, network: $network)
          }
        `,
        variables: {
          scriptId,
          network: testArgs.network,
          blockHash: testArgs.blockHash,
        },
      }),
      {
        loading: 'deleting unit test...',
        success: () => {
          onRefresh();
          setStatus('default');
          return 'Test deleted successfully';
        },
        error: e => {
          console.error('Failed to delete unit test', e);
          return `Failed to delete unit test`;
        },
      },
    )
  }

  const handleRename = (newName: string) => {
    toast.promise(
      apollo.mutate({
        mutation: gql`
          mutation RenameUnitTest($test: UnitTestRef!, $newName: String!) {
            renameUnitTest(test: $test, newName: $newName)
          }
        `,
        variables: {
          test: {
            blockHash: testArgs.blockHash,
            network: testArgs.network,
            scriptId,
            onDraft: isDraft,
            withTsCode: isDraft ? script.code : undefined,
            withFilter: isDraft ? script.filter : undefined,
          },
          newName,
        },
      }),
      {
        loading: 'Renaming unit test...',
        success: () => {
          onRefresh();
          return 'Test successfully renammed';
        },
        error: e => {
          console.error('Failed to rename unit test', e);
          return `Failed to rename unit test`;
        },
      },
    )
  }

  const handleSetAsFixClick = () => {
    toast.promise(
      apollo.query({
        query: gql`
          query setUnitTestAsFixed($test: UnitTestRef!) {
            setUnitTestAsFixed(test: $test)
          }
        `,
        variables: {
          test: {
            blockHash: testArgs.blockHash,
            network: testArgs.network,
            onDraft: isDraft,
            withTsCode: isDraft ? script.code : undefined,
            withFilter: isDraft ? script.filter : undefined,
            scriptId,
          },
        },
      }),
      {
        loading: 'Setting unit test as fixed...',
        success: () => {
          onRefresh();
          return 'Test set to fix';
        },
        error: e => {
          console.error('Failed to set unit test as fixed', e);
          return 'Failed to set unit test as fixed';
        },
      },
    )
  }

  const handleEditExpectedEmits = (newEmits: any) => {
    toast.promise(
      apollo.mutate({
        mutation: gql`
          mutation ModifyUnitTestExpectedEmits($test: UnitTestRef!, $expectedEmits: JSON!) {
            modifyUnitTestExpectedEmits(test: $test, expectedEmits: $expectedEmits)
          }
        `,
        variables: {
          test: {
            blockHash: testArgs.blockHash,
            network: testArgs.network,
            scriptId,
            onDraft: isDraft,
            withTsCode: isDraft ? script.code : undefined,
            withFilter: isDraft ? script.filter : undefined,
          },
          expectedEmits: newEmits,
        },
      }),
      {
        loading: 'Editing expected emits...',
        success: () => {
          onRefresh();
          return 'Expected emits successfully edited';
        },
        error: e => {
          console.error('Failed to edit expected emit', e);
          return `Failed to edit expected emit`;
        },
      }
    )
  }

  return (
    <AccordionItem value={name} className="border-none">
      <div className="flex justify-between items-center gap-2.5">
        <AccordionTrigger className="flex p-2.5 gap-2.5 items-center cursor-pointer [&>svg:last-child]:hidden [&[data-state=open]>svg]:rotate-90">
          <div className="flex items-center justify-center h-5 w-5">
            {statusIcon}
          </div>
          <ChevronRightIcon className="w-4 h-4" />
          <span>{name}</span>
        </AccordionTrigger>
        <div className="flex gap-2 justify-end">
          <Tooltip>
            <TooltipTrigger>
              <PlayCircleIcon data-disabled={status === 'running'} onClick={handleRunTestClick} role="button" className="w-4 h-4 data-[disabled=true]:text-muted-foreground data-[disabled=true]:cursor-default data-[disabled=true]:pointer-events-none" />
            </TooltipTrigger>
            <TooltipContent>
              Run test
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <EditTestName isDisabled={status === 'running'} currentName={name} onConfirm={handleRename} />
            </TooltipTrigger>
            <TooltipContent>
              Edit test name
            </TooltipContent>
          </Tooltip>
          <AlertDialog>
            <AlertDialogTrigger>
              <Tooltip>
                <TooltipTrigger>
                  <Trash2Icon data-disabled={status === 'running'} role="button" className="w-4 h-4 data-[disabled=true]:text-muted-foreground data-[disabled=true]:cursor-default data-[disabled=true]:pointer-events-none" />
                </TooltipTrigger>
                <TooltipContent>
                  Delete unit test
                </TooltipContent>
              </Tooltip>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{`Delete "${name}" unit test confirmation`}</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete this unit test?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleDeleteConfirm}>Confirm</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <AccordionContent className="flex flex-col rounded-lg bg-secondary py-0 overflow-clip">
        {status === 'default' && (
          <Payload initialValue={testArgs.expectedEmits} />
        )}
        {status === 'success' && (
          <Payload initialValue={expected} />
        )}
        {status === 'error' && (
          <DiffEditor
            height="250px"
            language="json"
            original={JSON.stringify(expected, null, 2)}
            modified={JSON.stringify(emitted, null, 2)}
            theme={theme === 'dark' ? 'vs-dark' : 'light'}
            options={{
              fontSize: 12,
              lineHeight: 14,
              fontWeight: 300,
              lineNumbers: 'on',
              readOnly: true,
              scrollbar: {
                verticalScrollbarSize: 7,
                horizontalScrollbarSize: 7,
              }
            }}
          />
        )}
        <div className="flex bg-muted rounded-b-lg items-center justify-end p-3 text-sm min-h-11 gap-1">
          <EditExpectedEmits value={testArgs.expectedEmits} onConfirm={handleEditExpectedEmits} />
          {status === 'error' && (
            <Button variant="secondary" className="gap-2" onClick={handleSetAsFixClick}>
              <CheckIcon className="w-4 h-4" />Fix: those emits are OK
            </Button>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  )
});
