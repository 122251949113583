import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './apollo';
import { ThemeProvider } from './components/theme-provider';
import { Toaster } from './components/ui/sonner';
import { TooltipProvider } from './components/ui/tooltip';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
    <RecoilRoot>
      <RecoilNexus />
      <ApolloProvider client={apolloClient}>
        <TooltipProvider delayDuration={100}>
          <App />
          <Toaster richColors />
        </TooltipProvider>
      </ApolloProvider>
    </RecoilRoot>
  </ThemeProvider>,
);
