/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetApiKeys
// ====================================================

export interface GetApiKeys_getApiTokens {
  readonly __typename: "Account_Token";
  readonly name: string;
  readonly token: string;
}

export interface GetApiKeys {
  /**
   * get all api tokens of the account
   */
  readonly getApiTokens: ReadonlyArray<GetApiKeys_getApiTokens>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddNewApiKey
// ====================================================

export interface AddNewApiKey {
  /**
   * generate a token for the account of the user, used to query script's results
   */
  readonly createApiToken: string;
}

export interface AddNewApiKeyVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyUser
// ====================================================

export interface MyUser_myUser {
  readonly __typename: "GUser";
  readonly address: HexString;
  readonly name: string;
  readonly id: UserId;
}

export interface MyUser {
  /**
   * Get my user details
   */
  readonly myUser: MyUser_myUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameMyUser
// ====================================================

export interface RenameMyUser {
  readonly renameMyUser: boolean;
}

export interface RenameMyUserVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccountList
// ====================================================

export interface GetAccountList_currentAccount {
  readonly __typename: "GAccount";
  readonly id: AccountId;
}

export interface GetAccountList_accounts {
  readonly __typename: "GAccountSummary";
  readonly id: AccountId;
  readonly name: string;
}

export interface GetAccountList {
  /**
   * Get the current account
   */
  readonly currentAccount: GetAccountList_currentAccount;
  /**
   * Get all accounts that the current user has access to
   */
  readonly accounts: ReadonlyArray<GetAccountList_accounts>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SwitchAccount
// ====================================================

export interface SwitchAccount {
  readonly switchAccount: string;
}

export interface SwitchAccountVariables {
  readonly accountId: AccountId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAccount
// ====================================================

export interface CreateAccount {
  readonly createAccount: string;
}

export interface CreateAccountVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentAccountName
// ====================================================

export interface GetCurrentAccountName_currentAccount {
  readonly __typename: "GAccount";
  readonly name: string;
}

export interface GetCurrentAccountName {
  /**
   * Get the current account
   */
  readonly currentAccount: GetCurrentAccountName_currentAccount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNonce
// ====================================================

export interface GetNonce {
  /**
   * Get a nonce string to sign
   */
  readonly getSignMessage: string;
}

export interface GetNonceVariables {
  readonly address: HexString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuthenticateWeb3
// ====================================================

export interface AuthenticateWeb3 {
  /**
   * Authenticate with a private key signed message that must contain the user's wallet address and a nonce provided by the server
   */
  readonly authenticate: string;
}

export interface AuthenticateWeb3Variables {
  readonly address: HexString;
  readonly signature: HexString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserList
// ====================================================

export interface GetUserList_currentAccount_users {
  readonly __typename: "GUser";
  readonly address: HexString;
  readonly name: string;
  readonly id: UserId;
}

export interface GetUserList_currentAccount {
  readonly __typename: "GAccount";
  readonly users: ReadonlyArray<GetUserList_currentAccount_users>;
}

export interface GetUserList {
  /**
   * Get the current account
   */
  readonly currentAccount: GetUserList_currentAccount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAccount
// ====================================================

export interface DeleteAccount {
  readonly setAccountMember: boolean;
}

export interface DeleteAccountVariables {
  readonly userAddress: HexString;
  readonly isMember: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteMember
// ====================================================

export interface InviteMember {
  readonly setAccountMember: boolean;
}

export interface InviteMemberVariables {
  readonly userAddress: HexString;
  readonly isMember: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameAccount
// ====================================================

export interface RenameAccount {
  /**
   * Rename the current account
   */
  readonly renameAccount: boolean;
}

export interface RenameAccountVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAddressList
// ====================================================

export interface GetAddressList_addressList {
  readonly __typename: "AddressList";
  readonly name: string;
  readonly list: ReadonlyArray<HexString>;
  /**
   * Number of elements in the list
   */
  readonly count: number;
}

export interface GetAddressList {
  readonly addressList: GetAddressList_addressList | null;
}

export interface GetAddressListVariables {
  readonly list: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchAddress
// ====================================================

export interface SearchAddress_addressList {
  readonly __typename: "AddressList";
  readonly has: boolean;
}

export interface SearchAddress {
  readonly addressList: SearchAddress_addressList | null;
}

export interface SearchAddressVariables {
  readonly list: string;
  readonly search: HexString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAddressListElt
// ====================================================

export interface DeleteAddressListElt {
  readonly addressListRemove: boolean;
}

export interface DeleteAddressListEltVariables {
  readonly list: string;
  readonly elt: HexString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddAddressListElt
// ====================================================

export interface AddAddressListElt {
  readonly addressListAdd: boolean;
}

export interface AddAddressListEltVariables {
  readonly list: string;
  readonly elt: HexString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAddressLists
// ====================================================

export interface GetAddressLists_addressLists {
  readonly __typename: "AddressListSummary";
  readonly name: string;
  /**
   * Number of elements in the list
   */
  readonly count: number;
}

export interface GetAddressLists {
  readonly addressLists: ReadonlyArray<GetAddressLists_addressLists>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAddressList
// ====================================================

export interface CreateAddressList {
  readonly newAddressList: boolean;
}

export interface CreateAddressListVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddressListDelete
// ====================================================

export interface AddressListDelete {
  readonly addressListDelete: boolean;
}

export interface AddressListDeleteVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetApiTokens
// ====================================================

export interface GetApiTokens_getApiTokens {
  readonly __typename: "Account_Token";
  readonly name: string;
  readonly token: string;
  readonly canWrite: boolean;
}

export interface GetApiTokens {
  /**
   * get all api tokens of the account
   */
  readonly getApiTokens: ReadonlyArray<GetApiTokens_getApiTokens>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApiToken
// ====================================================

export interface CreateApiToken {
  /**
   * generate a token for the account of the user, used to query script's results
   */
  readonly createApiToken: string;
}

export interface CreateApiTokenVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteApiToken
// ====================================================

export interface DeleteApiToken {
  /**
   * delete the api token of the user
   */
  readonly deleteApiToken: boolean;
}

export interface DeleteApiTokenVariables {
  readonly token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetApiTokenWriteAccess
// ====================================================

export interface SetApiTokenWriteAccess {
  readonly setApiTokenWriteAccess: boolean;
}

export interface SetApiTokenWriteAccessVariables {
  readonly token: string;
  readonly canWrite: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadGlobalBillingGraphPerScript
// ====================================================

export interface LoadGlobalBillingGraphPerScript {
  readonly billingGraphPerScript: JSON;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadGlobalBillingGraph
// ====================================================

export interface LoadGlobalBillingGraph_billingItems {
  readonly __typename: "BillingItem";
  readonly id: string;
  readonly name: string;
  readonly category: string;
}

export interface LoadGlobalBillingGraph {
  readonly billingGraph: JSON;
  readonly billingItems: ReadonlyArray<LoadGlobalBillingGraph_billingItems>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptDb
// ====================================================

export interface LoadScriptDb_scriptDb_entries {
  readonly __typename: "ScriptDbEntry";
  readonly key: string;
  readonly value: JSON;
}

export interface LoadScriptDb_scriptDb {
  readonly __typename: "ScriptDb";
  readonly name: string;
  readonly entries: ReadonlyArray<LoadScriptDb_scriptDb_entries>;
}

export interface LoadScriptDb {
  /**
   * Get a specific db
   */
  readonly scriptDb: LoadScriptDb_scriptDb;
}

export interface LoadScriptDbVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddNewDatabaseEntry
// ====================================================

export interface AddNewDatabaseEntry {
  /**
   * Saves an entry in a db
   */
  readonly setScriptDbEntry: boolean;
}

export interface AddNewDatabaseEntryVariables {
  readonly db: string;
  readonly key: string;
  readonly valueJson?: JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditDatabaseEntry
// ====================================================

export interface EditDatabaseEntry {
  /**
   * Saves an entry in a db
   */
  readonly setScriptDbEntry: boolean;
}

export interface EditDatabaseEntryVariables {
  readonly db: string;
  readonly key: string;
  readonly valueJson?: JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDatabaseEntry
// ====================================================

export interface DeleteDatabaseEntry {
  /**
   * Saves an entry in a db
   */
  readonly setScriptDbEntry: boolean;
}

export interface DeleteDatabaseEntryVariables {
  readonly db: string;
  readonly key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScriptDbs
// ====================================================

export interface ScriptDbs_scriptDbs {
  readonly __typename: "ScriptDbSummary";
  readonly name: string;
  /**
   * Last 24h requests graph
   */
  readonly graph: JSON;
  /**
   * Number of entries in the DB  (approximated, refreshed at least once per day)
   */
  readonly count: number;
  /**
   * DB size in bytes (approximated, refreshed at least once per day)
   */
  readonly size: number;
  /**
   * Number of requests today
   */
  readonly requestsToday: number;
}

export interface ScriptDbs {
  /**
   * Get my dbs
   */
  readonly scriptDbs: ReadonlyArray<ScriptDbs_scriptDbs>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScriptHistory
// ====================================================

export interface GetScriptHistory_script {
  readonly __typename: "Script";
  /**
   * If not null, the history will be stored for the given retention period (in seconds)
   */
  readonly storeHistory: number | null;
}

export interface GetScriptHistory {
  readonly script: GetScriptHistory_script;
}

export interface GetScriptHistoryVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptStats
// ====================================================

export interface LoadScriptStats_script {
  readonly __typename: "Script";
  readonly stats: JSON;
}

export interface LoadScriptStats {
  readonly script: LoadScriptStats_script;
}

export interface LoadScriptStatsVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptBillingGraph
// ====================================================

export interface LoadScriptBillingGraph_script {
  readonly __typename: "Script";
  readonly billingGraph: JSON;
}

export interface LoadScriptBillingGraph_billingItems {
  readonly __typename: "BillingItem";
  readonly id: string;
  readonly name: string;
  readonly category: string;
}

export interface LoadScriptBillingGraph {
  readonly script: LoadScriptBillingGraph_script;
  readonly billingItems: ReadonlyArray<LoadScriptBillingGraph_billingItems>;
}

export interface LoadScriptBillingGraphVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTsTypes
// ====================================================

export interface GetTsTypes {
  readonly tsTypes: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScriptTypes
// ====================================================

export interface GetScriptTypes {
  readonly scriptTypes: string;
}

export interface GetScriptTypesVariables {
  readonly code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptNameInfo
// ====================================================

export interface LoadScriptNameInfo_script {
  readonly __typename: "Script";
  readonly name: string | null;
}

export interface LoadScriptNameInfo {
  readonly script: LoadScriptNameInfo_script;
}

export interface LoadScriptNameInfoVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetNameInfo
// ====================================================

export interface SetNameInfo {
  readonly saveScriptOptions: boolean;
}

export interface SetNameInfoVariables {
  readonly id: ScriptId;
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptHistory
// ====================================================

export interface LoadScriptHistory_scriptMessages_hookStatus {
  readonly __typename: "GHookStatus";
  readonly hook: string;
  readonly processed: DateTime | null;
  readonly error: string | null;
}

export interface LoadScriptHistory_scriptMessages {
  readonly __typename: "GScriptMessage";
  readonly id: string;
  readonly blockNumber: HexNumber;
  readonly blockHash: string;
  readonly networkId: number;
  readonly network: string;
  readonly data: JSON | null;
  readonly at: DateTime;
  readonly hookStatus: ReadonlyArray<LoadScriptHistory_scriptMessages_hookStatus>;
}

export interface LoadScriptHistory {
  /**
   * Get script messages. You must set storeHistory=true on the script in order for this to yield results.
   */
  readonly scriptMessages: ReadonlyArray<LoadScriptHistory_scriptMessages>;
}

export interface LoadScriptHistoryVariables {
  readonly id: ScriptId;
  readonly cursor?: string | null;
  readonly take: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReplayHistoryLine
// ====================================================

export interface ReplayHistoryLine {
  /**
   * Replays a message that has already been sent
   */
  readonly replayMessage: boolean;
}

export interface ReplayHistoryLineVariables {
  readonly script: ScriptId;
  readonly msg: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptErrors
// ====================================================

export interface LoadScriptErrors_scriptErrors {
  readonly __typename: "GScriptError";
  readonly id: string;
  readonly blockNumber: HexNumber;
  readonly blockHash: string;
  readonly networkId: number;
  readonly network: string;
  readonly errors: ReadonlyArray<JSON>;
  readonly at: DateTime;
}

export interface LoadScriptErrors {
  /**
   * Get script errors
   */
  readonly scriptErrors: ReadonlyArray<LoadScriptErrors_scriptErrors>;
}

export interface LoadScriptErrorsVariables {
  readonly id: ScriptId;
  readonly cursor?: string | null;
  readonly take: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadWebhookErrors
// ====================================================

export interface LoadWebhookErrors_webhookErrors {
  readonly __typename: "GWebhookError";
  readonly id: string;
  readonly blockNumber: HexNumber;
  readonly blockHash: string;
  readonly networkId: number;
  readonly network: string;
  readonly error: string;
  readonly data: JSON;
  readonly at: DateTime;
  readonly fixed: boolean;
  readonly hook: string;
}

export interface LoadWebhookErrors {
  /**
   * Get webhook errors
   */
  readonly webhookErrors: ReadonlyArray<LoadWebhookErrors_webhookErrors>;
}

export interface LoadWebhookErrorsVariables {
  readonly id: ScriptId;
  readonly cursor?: string | null;
  readonly take: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReplayErrorLine
// ====================================================

export interface ReplayErrorLine {
  /**
   * Replay an error
   */
  readonly replayError: string;
}

export interface ReplayErrorLineVariables {
  readonly script: ScriptId;
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteErrorLine
// ====================================================

export interface DeleteErrorLine {
  /**
   * Replay an error
   */
  readonly deleteError: boolean;
}

export interface DeleteErrorLineVariables {
  readonly script: ScriptId;
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScriptHistoryState
// ====================================================

export interface GetScriptHistoryState_script {
  readonly __typename: "Script";
  /**
   * If not null, the history will be stored for the given retention period (in seconds)
   */
  readonly storeHistory: number | null;
}

export interface GetScriptHistoryState {
  readonly script: GetScriptHistoryState_script;
}

export interface GetScriptHistoryStateVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScript
// ====================================================

export interface LoadScript_script_draft {
  readonly __typename: "ScriptData";
  readonly code: string;
  readonly filter: JSON;
}

export interface LoadScript_script_deployed {
  readonly __typename: "ScriptData";
  readonly code: string;
  readonly filter: JSON;
}

export interface LoadScript_script_webhooks {
  readonly __typename: "Webhook";
  readonly url: string;
}

export interface LoadScript_script {
  readonly __typename: "Script";
  readonly id: ScriptId;
  readonly name: string | null;
  readonly draft: LoadScript_script_draft | null;
  readonly deployed: LoadScript_script_deployed;
  readonly webhooks: ReadonlyArray<LoadScript_script_webhooks>;
}

export interface LoadScript {
  readonly script: LoadScript_script;
}

export interface LoadScriptVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptProps
// ====================================================

export interface LoadScriptProps_script {
  readonly __typename: "Script";
  readonly id: ScriptId;
  /**
   * If not null, the history will be stored for the given retention period (in seconds)
   */
  readonly storeHistory: number | null;
}

export interface LoadScriptProps {
  readonly script: LoadScriptProps_script;
}

export interface LoadScriptPropsVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetKeepHistoryDelay
// ====================================================

export interface SetKeepHistoryDelay {
  readonly saveScriptOptions: boolean;
}

export interface SetKeepHistoryDelayVariables {
  readonly id: ScriptId;
  readonly storeHistoryDelay?: number | null;
  readonly storeHistory?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAddressListsForConstraint
// ====================================================

export interface GetAddressListsForConstraint_addressLists {
  readonly __typename: "AddressListSummary";
  readonly name: string;
  /**
   * Number of elements in the list
   */
  readonly count: number;
}

export interface GetAddressListsForConstraint {
  readonly addressLists: ReadonlyArray<GetAddressListsForConstraint_addressLists>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ParseEvent
// ====================================================

export interface ParseEvent {
  readonly parseEvent: JSON;
}

export interface ParseEventVariables {
  readonly code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestForkedScript
// ====================================================

export interface TestForkedScript_testScript {
  readonly __typename: "TestScriptResult";
  readonly events: ReadonlyArray<JSON>;
  readonly state: JSON;
}

export interface TestForkedScript {
  readonly testScript: TestForkedScript_testScript | null;
}

export interface TestForkedScriptVariables {
  readonly input: TestScriptInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNewAddressList
// ====================================================

export interface CreateNewAddressList {
  readonly newAddressList: boolean;
}

export interface CreateNewAddressListVariables {
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddressListAdd
// ====================================================

export interface AddressListAdd {
  readonly addressListAdd: boolean;
}

export interface AddressListAddVariables {
  readonly name: string;
  readonly items: ReadonlyArray<HexString>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptName
// ====================================================

export interface LoadScriptName_script {
  readonly __typename: "Script";
  readonly name: string | null;
}

export interface LoadScriptName {
  readonly script: LoadScriptName_script;
}

export interface LoadScriptNameVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadScriptStatus
// ====================================================

export interface LoadScriptStatus_script {
  readonly __typename: "Script";
  readonly status: ScriptStatus;
}

export interface LoadScriptStatus {
  readonly script: LoadScriptStatus_script;
}

export interface LoadScriptStatusVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetName
// ====================================================

export interface SetName {
  readonly saveScriptOptions: boolean;
}

export interface SetNameVariables {
  readonly id: ScriptId;
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScriptList
// ====================================================

export interface ScriptList_scripts {
  readonly __typename: "ScriptSummary";
  readonly id: ScriptId;
  readonly name: string | null;
  readonly status: ScriptStatus;
  readonly stats: JSON;
}

export interface ScriptList {
  readonly scripts: ReadonlyArray<ScriptList_scripts>;
}

export interface ScriptListVariables {
  readonly skip?: number | null;
  readonly take: number;
  readonly search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScript
// ====================================================

export interface DeleteScript {
  readonly deleteScript: boolean;
}

export interface DeleteScriptVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckTestBlockExist
// ====================================================

export interface CheckTestBlockExist_unitTests {
  readonly __typename: "UnitTest";
  readonly blockNumber: HexNumber;
}

export interface CheckTestBlockExist {
  readonly unitTests: ReadonlyArray<CheckTestBlockExist_unitTests>;
}

export interface CheckTestBlockExistVariables {
  readonly scriptId: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestScript
// ====================================================

export interface TestScript_testScript {
  readonly __typename: "TestScriptResult";
  readonly events: ReadonlyArray<JSON>;
  readonly state: JSON;
}

export interface TestScript {
  readonly testScript: TestScript_testScript | null;
}

export interface TestScriptVariables {
  readonly input: TestScriptInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunAndDispatch
// ====================================================

export interface RunAndDispatch_executeAndDispatch {
  readonly __typename: "TestScriptResult";
  readonly events: ReadonlyArray<JSON>;
  readonly state: JSON;
}

export interface RunAndDispatch {
  readonly executeAndDispatch: RunAndDispatch_executeAndDispatch | null;
}

export interface RunAndDispatchVariables {
  readonly input: TestScriptInput;
  readonly script: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MakeTest
// ====================================================

export interface MakeTest_newUnitTest {
  readonly __typename: "TestScriptResult";
  readonly error: string | null;
  readonly events: ReadonlyArray<JSON>;
  readonly filteredOut: boolean;
  readonly state: JSON;
}

export interface MakeTest {
  readonly newUnitTest: MakeTest_newUnitTest;
}

export interface MakeTestVariables {
  readonly input: MakeUnitTestInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunUnitTest
// ====================================================

export interface RunUnitTest_runUnitTest {
  readonly __typename: "UnitTestResult";
  readonly passed: boolean;
  readonly emitted: ReadonlyArray<JSON>;
  readonly expected: JSON;
}

export interface RunUnitTest {
  readonly runUnitTest: RunUnitTest_runUnitTest;
}

export interface RunUnitTestVariables {
  readonly test: UnitTestRef;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeleteUnitTest
// ====================================================

export interface DeleteUnitTest {
  readonly deleteUnitTest: boolean;
}

export interface DeleteUnitTestVariables {
  readonly scriptId: ScriptId;
  readonly blockHash: HexString;
  readonly network: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameUnitTest
// ====================================================

export interface RenameUnitTest {
  readonly renameUnitTest: boolean;
}

export interface RenameUnitTestVariables {
  readonly test: UnitTestRef;
  readonly newName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: setUnitTestAsFixed
// ====================================================

export interface setUnitTestAsFixed {
  readonly setUnitTestAsFixed: boolean;
}

export interface setUnitTestAsFixedVariables {
  readonly test: UnitTestRef;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ModifyUnitTestExpectedEmits
// ====================================================

export interface ModifyUnitTestExpectedEmits {
  readonly modifyUnitTestExpectedEmits: boolean;
}

export interface ModifyUnitTestExpectedEmitsVariables {
  readonly test: UnitTestRef;
  readonly expectedEmits: JSON;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnitTests
// ====================================================

export interface UnitTests_unitTests {
  readonly __typename: "UnitTest";
  readonly blockHash: HexString;
  readonly blockNumber: HexNumber;
  readonly expectedEmits: JSON;
  readonly name: string;
  readonly network: string;
}

export interface UnitTests {
  readonly unitTests: ReadonlyArray<UnitTests_unitTests>;
}

export interface UnitTestsVariables {
  readonly scriptId: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveScript
// ====================================================

export interface SaveScript {
  readonly saveDraft: ScriptId;
}

export interface SaveScriptVariables {
  readonly id?: ScriptId | null;
  readonly input: SaveDraftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetScriptName
// ====================================================

export interface SetScriptName {
  readonly saveScriptOptions: boolean;
}

export interface SetScriptNameVariables {
  readonly id: ScriptId;
  readonly name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeployDraft
// ====================================================

export interface DeployDraft {
  readonly deployDraft: boolean;
}

export interface DeployDraftVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadWebhooks
// ====================================================

export interface LoadWebhooks_script_webhooks {
  readonly __typename: "Webhook";
  readonly url: string;
}

export interface LoadWebhooks_script {
  readonly __typename: "Script";
  readonly webhooks: ReadonlyArray<LoadWebhooks_script_webhooks>;
}

export interface LoadWebhooks {
  readonly script: LoadWebhooks_script;
}

export interface LoadWebhooksVariables {
  readonly id: ScriptId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddWebhookUrl
// ====================================================

export interface AddWebhookUrl {
  readonly saveScriptOptions: boolean;
}

export interface AddWebhookUrlVariables {
  readonly id: ScriptId;
  readonly webhooks?: ReadonlyArray<string> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWebhookUrl
// ====================================================

export interface DeleteWebhookUrl {
  readonly saveScriptOptions: boolean;
}

export interface DeleteWebhookUrlVariables {
  readonly id: ScriptId;
  readonly webhooks?: ReadonlyArray<string> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditWebhookUrl
// ====================================================

export interface EditWebhookUrl {
  readonly saveScriptOptions: boolean;
}

export interface EditWebhookUrlVariables {
  readonly id: ScriptId;
  readonly webhooks?: ReadonlyArray<string> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Status of a script
 */
export enum ScriptStatus {
  active = "active",
  alwaysActive = "alwaysActive",
  draft = "draft",
  paused = "paused",
  paymentRequired = "paymentRequired",
  waitingPeers = "waitingPeers",
}

export interface MakeUnitTestInput {
  readonly name?: string | null;
  readonly scriptId: ScriptId;
  readonly network: string;
  readonly block: string;
  readonly state?: JSON | null;
}

export interface SaveDraftInput {
  readonly ts: string;
  readonly filter: JSON;
}

export interface TestScriptInput {
  readonly network: string;
  readonly block: string;
  readonly ts?: string | null;
  readonly filter: JSON;
  readonly state?: JSON | null;
}

export interface UnitTestRef {
  readonly scriptId: ScriptId;
  readonly onDraft?: boolean | null;
  readonly withTsCode?: string | null;
  readonly withFilter?: JSON | null;
  readonly network: string;
  readonly blockHash: HexString;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
